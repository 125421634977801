import axios from '../libs/axios'
import { getFormDataFromJSON } from '@/api/common'

const getPaginatedArtWorks = queryParams => axios.post('/art/get_all_artwork', getFormDataFromJSON(queryParams))

const getArtworkById = id => axios.get(`/art/get_artwork_by_id/${id}`)

const updateArtwork = artWorkObj => axios.put('/art/edit_artwork', artWorkObj)

const getAllArtworkOfCreator = (creatorId, queryParams) => axios.post(`/art/get_all_art_of_creator/${creatorId}`, queryParams)

const testMethod = () => 'test'

export {
  getPaginatedArtWorks,
  testMethod,
  getArtworkById,
  updateArtwork,
  getAllArtworkOfCreator,
}
