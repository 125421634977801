<template>
  <div class="art-work">
    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-col md="12">
        <div class="d-flex flex-row">
          <div>Pending</div>
          <b-form-checkbox
            v-model="paginationData.approved"
            class="custom-control-secondary ml-1"
            name="switch-approved"
            switch
          />
          <div>Approved</div>
        </div>
      </b-col>
      <b-col md="12">
        <small>Total No of Records: {{ totalRecords }}</small>
      </b-col>
      <b-col
        v-if="!loading && totalRecords===0 && !paginationData.approved"
        md="12"
        class="text-center"
      >
        <small>No Image Pending For Approval</small>
      </b-col>
      <b-col
        v-if="loading"
        md="12"
      >
        <div class="text-center">
          <b-spinner
            variant="primary"
            label="Please wait..."
          />
        </div>
      </b-col>
      <b-col
        v-for="artWork in artWorkList"
        v-else
        :key="artWork.img"
        class="mt-3"
        md="4"
      >
        <b-card
          tag="article"
          style="height: 100% !important"
          no-body
        >
          <b-link :to="{ name: 'artworks', params: { id: artWork.id } }">
            <b-img
              height="350"
              :src="artWork.waterMarkedImagePath"
              :alt="artWork.waterMarkedImagePath.slice(5)"
              class="card-img-top"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'artworks', params: { id: artWork.id } }"
                class="artWork-title-truncate text-body-heading"
              >
                <b-row>
                  <b-col md="9">
                    {{ artWork.name }}
                    <feather-icon
                      :id="artWork.id"
                      icon="EditIcon"
                      @click="openModal(artWork.id)"
                    />
                  </b-col>
                  <b-col md="3">
                    <h6 class="float-right">
                      Price: {{ artWork.price ? artWork.price : artWork.salePrice }}
                    </h6>
                  </b-col>
                </b-row>

              </b-link>
            </b-card-title>
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  href="javascript:void(0)"
                  size="24"
                  :src="artWork.avatar"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">by</small>
                <small>
                  <b-link class="text-body">{{
                    artWork.creator.fullName
                  }}</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ new Date(artWork.created).toLocaleString() }}</small>
              </b-media-body>
            </b-media>
            <div class="my-1 py-25">
              <b-link
                v-for="(tag, index) in artWork.tags"
                :key="index"
              >
                <b-badge
                  pill
                  class="mr-75"
                  variant="light-primary"
                >
                  {{ tag.name }}
                </b-badge>
              </b-link>
            </div>
            <b-card-text class="blog-content-truncate" />
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <div
                v-b-tooltip.hover.top="kFormatter(artWork.description)"
                class="d-flex align-items-center text-body"
              >
                <span
                  class="font-weight-bold"
                >{{ (artWork.description.length>200) ? artWork.description.substring(0, 200) + " ..." : artWork.description }}</span>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            :value="paginationData.page + 1"
            align="center"
            :total-rows="totalRecords"
            :per-page="paginationData.page_size"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @change="pageChanged"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <EditArtWork
      ref="editArt"
      @fetch-artwork="fetchArtWorks"
    />
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BPagination,
  BFormCheckbox,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { onMounted, ref, watch } from '@vue/composition-api'
import { kFormatter } from '@core/utils/filter'
import { getPaginatedArtWorks } from '@/api/artWork'
import EditArtWork from '../components/ArtWork/EditArtWork.vue'

export default {
  name: 'ArtWorks',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BImg,
    BPagination,
    BFormCheckbox,
    BSpinner,
    // BButton,
    EditArtWork,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const editArt = ref()
    const loading = ref(false)
    const artWorkList = ref([])
    const paginationData = ref({
      page: 0,
      page_size: 10,
      approved: false,
    })
    const totalRecords = ref(0)
    // Methods
    const fetchArtWorks = async () => {
      loading.value = true
      const { data } = await getPaginatedArtWorks(paginationData.value)
      totalRecords.value = data.totalElements
      artWorkList.value = data.content
      loading.value = false
    }
    const openModal = id => {
      editArt.value.toggleModal(id)
    }
    const pageChanged = value => {
      paginationData.value.page = value - 1
    }
    // End Methods
    // Watch
    watch(paginationData.value, fetchArtWorks)
    // Mounted
    onMounted(() => {
      fetchArtWorks()
    })
    // End Mounted
    return {
      // Var
      artWorkList,
      paginationData,
      totalRecords,
      loading,
      // Methods
      fetchArtWorks,
      kFormatter,
      pageChanged,
      editArt,
      openModal,
    }
  },
}
</script>

<style scoped></style>
