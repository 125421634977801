<template>
  <b-modal
    id="edit-artist-info"
    ref="editArtistInfo"
    hide-footer
    title="Artwork Manager"
  >
    <div class="d-block">
      <validation-observer ref="updateArtworkForm">
        <b-form @submit.prevent="validationForm">
          <b-form-group
            label="Artwork Name"
            label-for="artwork_name"
          >
            <b-form-input
              id="artwork_name"
              v-model="artworkObj.name"
            />
          </b-form-group>

          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="textarea-default"
              v-model="artworkObj.description"
              placeholder="Default textarea"
              max-rows="4"
            />
          </b-form-group>
          <b-form-group
            label="Actual Price"
            label-for="actual_price"
          >
            <validation-provider
              #default="{ errors }"
              name="Price"
              rules="required"
            >
              <b-form-input
                id="actual_price"
                v-model="artworkObj.price"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Quoted Price"
            label-for="quoted_price"
          >
            <b-form-input
              id="quoted_price"
              v-model="artworkObj.salePrice"
            />
          </b-form-group>
          <b-row>
            <b-col
              cols="4"
              class="d-flex align-items-center justify-content-start"
            >
              <b-form-checkbox
                v-model="artworkObj.approved"
                class="custom-control-success"
              >
                Approved
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="4"
              class="d-flex align-items-center justify-content-start"
            >
              <b-form-checkbox
                v-model="artworkObj.putOnSale"
                class="custom-control-success"
              >
                Put On Sale
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="4"
              class="d-flex align-items-center justify-content-start"
            >
              <b-form-checkbox
                v-model="artworkObj.croppingAllowed"
                class="custom-control-success"
              >
                Cropping
              </b-form-checkbox>
            </b-col>
          </b-row>
          <div>
            <b-img
              height="300"
              width="300"
              :src="artworkObj.waterMarkedImagePath"
              :alt="artworkObj.waterMarkedImagePath.slice(5)"
              class="card-img-top mt-2"
            />
          </div>

          <b-button
            class="mt-3"
            variant="outline-success"
            type="submit"
            block
          >
            Update
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BImg,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
import { getArtworkById, updateArtwork } from '../../api/artWork'

export default {
  name: 'EditArtWork',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormCheckbox,
    BImg,
    BFormTextarea,
  },
  setup(props, context) {
    const swal = context.root.$swal
    const artworkObj = ref({
      id: 0,
      name: '',
      description: '',
      putOnSale: '',
      salePrice: '',
      price: '',
      croppingAllowed: '',
      approved: '',
      waterMarkedImagePath: '',
    })
    const editArtistInfo = ref()
    const updateArtworkForm = ref()
    const hideModal = () => {
      editArtistInfo.value.hide()
    }
    const showLoading = () => {
      swal.fire({
        title: 'Please Wait! We are processing the data.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          swal.showLoading()
        },
      })
    }
    const toggleModal = async artworkId => {
      showLoading()
      const { data } = await getArtworkById(artworkId)
      artworkObj.value = data
      editArtistInfo.value.show()
      swal.close()
    }
    const validationForm = () => {
      updateArtworkForm.value.validate().then(async success => {
        if (success) {
          showLoading()
          await updateArtwork(artworkObj.value)
          context.emit('fetch-artwork')
          hideModal()
          swal('Success', 'Artwork Has Been Updated', 'success')
        }
      })
    }
    return {
      artworkObj,
      editArtistInfo,
      updateArtworkForm,
      required,
      hideModal,
      toggleModal,
      validationForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
